var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DefaultLayout", [
    _c("div", { staticClass: "container py-24 grid" }, [
      _c("div", { staticClass: "text-center pt-4 mb-4" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("registration.title")))]),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("registration.helpText")) }
        })
      ]),
      _vm.invalid
        ? _c("p", { staticClass: "error text-red-500 text-center mb-2" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("registration.error")))])
          ])
        : _vm._e(),
      _vm.registered
        ? _c("p", { staticClass: "error text-green-500 text-center mb-2" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("registration.registered")))])
          ])
        : _vm._e(),
      !_vm.registered
        ? _c("div", { staticClass: "bg-white" }, [
            _c("div", { staticStyle: { "overflow-x": "auto" } }, [
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c(
                    "tr",
                    _vm._l(_vm.questions, function(question) {
                      return _c(
                        "th",
                        { key: question.id, staticClass: "align-bottom" },
                        [
                          _vm.getAmountofAnswers(question, "Ja") > 0
                            ? _c(
                                "small",
                                {
                                  staticClass: "bg-green-300 p-1 mr-1 rounded"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getAmountofAnswers(question, "Ja")
                                      ) +
                                      " Ja "
                                  ),
                                  _c("br")
                                ]
                              )
                            : _vm._e(),
                          _vm.getAmountofAnswers(question, "Evt.") > 0
                            ? _c(
                                "small",
                                {
                                  staticClass: "bg-orange-300 p-1 mr-1 rounded"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getAmountofAnswers(question, "Evt.")
                                      ) +
                                      " Evt."
                                  ),
                                  _c("br")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" " + _vm._s(question.name) + " "),
                          _c("br")
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _c("tbody", [
                  _c(
                    "tr",
                    _vm._l(_vm.questions, function(question) {
                      return _c(
                        "td",
                        { key: question.id },
                        _vm._l(_vm.options, function(option) {
                          return _c(
                            "button",
                            {
                              key: option.id,
                              staticClass: "p-1 btn d-block border-0",
                              class: {
                                "bg-green-300 text-black":
                                  question.selected === option.id &&
                                  option.name === "Ja",
                                " bg-red-300 text-black":
                                  question.selected === option.id &&
                                  option.name === "Nein",
                                " bg-orange-300 text-black":
                                  question.selected === option.id &&
                                  option.name === "Evt."
                              },
                              staticStyle: {
                                display: "block",
                                width: "100%",
                                border: "none",
                                "margin-right": "3px",
                                "margin-bottom": "3px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.changeOption(question, option)
                                }
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(option.name) + " "),
                              option.name === "Nein"
                                ? _c("svg-icon", {
                                    staticClass:
                                      "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                                    attrs: { name: "close" }
                                  })
                                : _vm._e(),
                              option.name === "Ja"
                                ? _c("svg-icon", {
                                    staticClass:
                                      "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                                    attrs: { name: "check-1" }
                                  })
                                : _vm._e(),
                              option.name === "Evt."
                                ? _c(
                                    "p",
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                                        attrs: {
                                          name: "information-desk-question-help"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    }),
                    0
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "max-w-xs mt-4 px-3 pb-3" }, [
              _c("b", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("registration.name")))
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name"
                  }
                ],
                staticClass:
                  "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
                domProps: { value: _vm.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  }
                }
              }),
              _c("b", { staticClass: "mt-4 " }, [
                _vm._v(_vm._s(_vm.$t("registration.mail")))
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mail,
                    expression: "mail"
                  }
                ],
                staticClass:
                  " shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
                attrs: { type: "email" },
                domProps: { value: _vm.mail },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.mail = $event.target.value
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass:
                    "bg-black px-2 mt-3 py-2 btn text-white  hover:cursor-pointer",
                  attrs: { type: "submit" },
                  on: { click: _vm.sendRegistration }
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("registration.submit")) + " "),
                  _c("svg-icon", {
                    staticClass:
                      "inline-block w-5 h-5 -mt-1 ml-1 mr-1  text-whitesvg-icon",
                    attrs: { name: "submit" }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "text-center pt-10 mb-4" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("registration.existing")))])
      ]),
      _c("div", { staticStyle: { "overflow-x": "auto" } }, [
        _c("table", { staticClass: " table" }, [
          _c("thead", [
            _c(
              "tr",
              [
                _c("th", { staticClass: "align-bottom" }, [_vm._v("Name")]),
                _vm._l(_vm.questions, function(question) {
                  return _c(
                    "th",
                    { key: question.id, staticClass: "align-bottom" },
                    [
                      _vm.getAmountofAnswers(question, "Ja") > 0
                        ? _c(
                            "small",
                            { staticClass: "bg-green-300 p-1 mr-1 rounded" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getAmountofAnswers(question, "Ja")
                                  ) +
                                  " Ja "
                              ),
                              _c("br")
                            ]
                          )
                        : _vm._e(),
                      _vm.getAmountofAnswers(question, "Evt.") > 0
                        ? _c(
                            "small",
                            { staticClass: "bg-orange-300 p-1 mr-1 rounded" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getAmountofAnswers(question, "Evt.")
                                  ) +
                                  " Evt."
                              ),
                              _c("br")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(question.name) + " ")
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "tbody",
            _vm._l(_vm.registrations, function(registration) {
              return _c(
                "tr",
                { key: registration.id },
                [
                  _c("td", [_c("b", [_vm._v(_vm._s(registration.name))])]),
                  _vm._l(registration.questions, function(question) {
                    return _c(
                      "td",
                      {
                        key: question.id,
                        class: _vm.getClass(question.option)
                      },
                      [
                        question.option === "Ja"
                          ? _c("svg-icon", {
                              staticClass:
                                "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                              attrs: { name: "check-1" }
                            })
                          : _vm._e(),
                        question.option === "Evt."
                          ? _c(
                              "p",
                              [
                                _c("svg-icon", {
                                  staticClass:
                                    "inline-block w-10 h-10 p-2 text-whitesvg-icon",
                                  attrs: {
                                    name: "information-desk-question-help"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }