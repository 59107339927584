<template>
  <DefaultLayout>
    <div class="container py-24 grid">
      <div class="text-center pt-4 mb-4">
        <h2>{{ $t('registration.title') }}</h2>
        <p v-html="$t('registration.helpText')"></p>
      </div>
      <p v-if="invalid" class="error text-red-500 text-center mb-2">
        <b>{{ $t('registration.error') }}</b>
      </p>
      <p v-if="registered" class="error text-green-500 text-center mb-2">
        <b>{{ $t('registration.registered') }}</b>
      </p>

      <div class="bg-white" v-if="!registered">
        <div style="overflow-x:auto;">
          <table  class="table">
            <thead>
            <tr>
              <th v-for="question in questions" :key="question.id" class="align-bottom">
                <small class="bg-green-300 p-1 mr-1 rounded" v-if="getAmountofAnswers(question, 'Ja') > 0">
                  {{ getAmountofAnswers(question, "Ja") }}&nbsp;Ja <br></small>
                <small class="bg-orange-300 p-1 mr-1 rounded" v-if="getAmountofAnswers(question, 'Evt.') > 0">
                  {{ getAmountofAnswers(question, "Evt.") }}&nbsp;Evt.<br></small>
                {{ question.name }} <br>

              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td v-for="question in questions" :key="question.id">
                <button v-for="option in options"
                        :key="option.id"
                        :class="{ 'bg-green-300 text-black' :
                      question.selected === option.id && option.name === 'Ja',
                      ' bg-red-300 text-black':
                       question.selected === option.id && option.name === 'Nein',
                      ' bg-orange-300 text-black':
                       question.selected === option.id && option.name === 'Evt.'
                      }"
                        class="p-1 btn d-block border-0"
                        style="display: block; width: 100%; border:none; margin-right: 3px; margin-bottom: 3px"
                        @click="changeOption(question, option)">
                  {{option.name}}
                  <svg-icon
                      v-if="option.name === 'Nein'"
                      class="inline-block w-10 h-10 p-2 text-whitesvg-icon"
                      name="close"
                  />
                  <svg-icon
                      v-if="option.name === 'Ja'"
                      class="inline-block w-10 h-10 p-2 text-whitesvg-icon"
                      name="check-1"
                  />

                  <p v-if="option.name === 'Evt.'">
                    <svg-icon
                        class="inline-block w-10 h-10 p-2 text-whitesvg-icon"
                        name="information-desk-question-help"
                    />
                  </p>

                </button>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
        <div class="max-w-xs mt-4 px-3 pb-3">
          <b class="mt-3">{{ $t('registration.name') }}</b>
          <input
              v-model="name"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />

          <b class="mt-4 ">{{ $t('registration.mail') }}</b>
          <input
              v-model="mail"
              type="email"
              class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />

          <button
              type="submit"
              class="bg-black px-2 mt-3 py-2 btn text-white  hover:cursor-pointer"
              @click="sendRegistration"
          >

            {{ $t('registration.submit') }}
            <svg-icon
                class="inline-block w-5 h-5 -mt-1 ml-1 mr-1  text-whitesvg-icon"
                name="submit"
            />
          </button>
        </div>
      </div>
      <div class="text-center pt-10 mb-4">
        <h2>{{ $t('registration.existing') }}</h2>
      </div>
      <div style="overflow-x:auto;" >
        <table class=" table">
          <thead>
          <tr>
            <th class="align-bottom">Name</th>
            <th v-for="question in questions" :key="question.id" class="align-bottom">
              <small class="bg-green-300 p-1 mr-1 rounded" v-if="getAmountofAnswers(question, 'Ja') > 0">
                {{ getAmountofAnswers(question, "Ja") }}&nbsp;Ja <br></small>
              <small class="bg-orange-300 p-1 mr-1 rounded" v-if="getAmountofAnswers(question, 'Evt.') > 0">
                {{ getAmountofAnswers(question, "Evt.") }}&nbsp;Evt.<br></small>
              {{ question.name }}

            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="registration in registrations" :key="registration.id">
            <td>
              <b>{{ registration.name }}</b>
            </td>
            <td
                v-for="question in registration.questions"
                :key="question.id"
                :class="getClass(question.option)"
            >
              <svg-icon
                  v-if="question.option === 'Ja'"
                  class="inline-block w-10 h-10 p-2 text-whitesvg-icon"
                  name="check-1"
              />
              <p v-if="question.option === 'Evt.'">
                <svg-icon
                    class="inline-block w-10 h-10 p-2 text-whitesvg-icon"
                    name="information-desk-question-help"
                />
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import {
  getQuestions,
  getOptions,
  getRegistrations,
  saveRegistration,
} from '@/services/camping';
import Vue from 'vue';

export default {
  name: 'Registration',
  data() {
    return {
      invalid: false,
      registered: false,
      name: '',
      mail: '',
      questions: [],
      options: [],
      registrations: [],
      signupcache: {},
    };
  },
  async mounted() {
    this.options = await getOptions();

    let questions = await getQuestions()
    questions.forEach((q) => {
      q.selected = this.options[0].id;

    });
    this.questions.push(...questions)
    this.registrations = await getRegistrations();


    this.updateCache()
  },
  methods: {
    getClass(option) {
      if (option === 'Ja') {
        return 'bg-green-300 text-center';
      }
      if (option === 'Nein') {
        return 'border text-center';
      }
      if (option === 'Evt.') {
        return 'bg-orange-300 text-center';
      }
    },
    validateEmail(email) {
      return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    },
    changeOption(q, option) {
      this.questions.forEach(function (question) {
        if (question.id === q.id) {
          question.selected = option.id;
        }
      });
    },
    updateCache() {
      this.signupcache = {}
      this.registrations.forEach(registration => {
        registration.questions.forEach(question => {
          if (!this.signupcache[question.question]) {
            this.signupcache[question.question] = {}
          }
          if (!this.signupcache[question.question][question.option]) {
            this.signupcache[question.question][question.option] = 0;
          }
          this.signupcache[question.question][question.option]++;

        })
      });
    },
    getAmountofAnswers(question, answer) {
      if (!this.signupcache[question.id]) {
        return 0;
      }
      if (!this.signupcache[question.id][answer]) {
        return 0;
      }
      return this.signupcache[question.id][answer];
    },
    async sendRegistration() {
      let error = false;
      if (!this.name || !this.mail || !this.validateEmail(this.mail)) {
        error = true;
      }
      this.questions.forEach((q) => {
        if (!q.selected) {
          error = true;
        }
      });
      if (error) {
        this.invalid = true;
        return;
      }
      this.invalid = false;
      let payload = {
        name: this.name,
        email: this.mail,
        questions: this.questions,
      };
      await saveRegistration(payload);
      this.registrations = await getRegistrations();
      this.questions = await getQuestions();
      this.name = '';
      this.mail = '';
      this.registered = true;

      this.updateCache()
    },
  },
};
</script>
