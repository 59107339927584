import { userApi } from './api';

const getQuestions = async () => {
  const response = await userApi.get('camping/questions');
  return response.data;
};

const getOptions = async () => {
  const response = await userApi.get('camping/options');
  return response.data;
};

const getRegistrations = async () => {
  const response = await userApi.get('camping/registrations');
  return response.data;
};

const saveRegistration = async (payload) => {
  const response = await userApi.post('camping/registrations', payload);
  return response.data;
};

export { getQuestions, getOptions, saveRegistration, getRegistrations };
